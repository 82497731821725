<template>
  <div>
    <Main />
    <Hedgy />
  </div>
</template>
<script>

import Main from "../components/farm-HEDGY/Main.vue";
import Hedgy from "../components/home/Hedgy.vue";
export default {
  components: {
    Main,
    Hedgy,
  },
};
</script>
